import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  InputAdornment,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

import dayjs from 'dayjs'

import {db} from './firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { 
  doc, 
  setDoc,
  where,
  collection,
  query,
  getDocs
} from "firebase/firestore"; 

const auth = getAuth();
const theme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
          CV Manages Properties
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {

  const navigate = useNavigate()

  const [loadingProperties, setLoadingProperties] = useState(true)
  const [loadingSignUp, setLoadingSignUp] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [ecPhoneNumber, setEcPhoneNumber] = useState("")
  const [properties, setProperties] = useState([])
  const [property, setProperty] = useState('')
  const [leaseLength, setLeaseLength] = useState(0)
  const [monthlyRent, setMonthlyRent] = useState('')

  useEffect(() => {
    
    const getProperties = async () => {
      const properties = []
      const querySnapshot = await getDocs(query(collection(db, "properties")))
      querySnapshot.forEach((doc) => {
        const property = {
          id: doc.id,
          address: doc.data().address,
          monthlyRent: doc.data().monthlyRent,
          leaseLength: doc.data().leaseLength
        }
        properties.push(property)
      })

      if(properties.length > 0){
        setLeaseLength(properties[0].leaseLength)
        setMonthlyRent(properties[0].monthlyRent)
        setProperties(properties)
        setProperty(properties[0].address) 
      }
    }
    
    getProperties()
    .then(() => setLoadingProperties(false))
    .catch((error) => alert("Error getting properties: " + error))

  }, [])


  async function handleSignUp(event){
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    if(data.get('ec-name') === '' || data.get('ec-phone') === '' || data.get('ec-relationship') === '' || data.get('phone') === '' || data.get('email') === '' || data.get('password') === '' || data.get('firstName') === '' || data.get('lastName') === ''){
      setErrorMessage('Error: All inputs must be filled in')
      setTimeout(() => setErrorMessage(''), 6000)
      return
    }

    setLoadingSignUp(true)

    //  Getting property id
    let propertyId = ''
    for(let i=0; i<properties.length; i++){
      if(properties[i].address === property){
        propertyId = properties[i].id
        break
      }
    }

    createUserWithEmailAndPassword(auth, data.get('email'), data.get('password'))
    .then(async (userCredential) => {

      await setDoc(doc(db, 'renters', userCredential.user.uid), {
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        email: data.get('email'),
        phone: phoneNumber,
        propertyId: propertyId,
        customerId: '',
        ecName: data.get('ec-name'),
        ecPhone: ecPhoneNumber,
        ecRelationship: data.get('ec-relationship'),
        memberSince: dayjs().format('MM/DD/YYYY hh:mm a'),
        renterId: userCredential.user.uid
      })

      navigate("/billing", { replace: true })
      setLoadingSignUp(false)
    })
    .catch((error) => {
      let errorMessage = ''
      if(error.message.includes('auth/invalid-email')){
        errorMessage = 'Error: Invalid email'
      }
      else if(error.message.includes('email-already-in-use')){
        errorMessage = 'Error: Email already in use'
      }
      else if(error.message.includes('weak-password')){
        errorMessage = 'Error: Password should be at least 6 characters'
      }
      else{
        errorMessage = error.message
      }
      setLoadingSignUp(false)
      setErrorMessage(errorMessage)
      setTimeout(() => setErrorMessage(''), 6000)
    })
  }

  function formatPhoneNumber(value){
    if (!value) {
      return value
    }
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) {
      return phoneNumber
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  function changePhoneNumber(num){
    const formattedPhoneNumber = formatPhoneNumber(num)
    setPhoneNumber(formattedPhoneNumber)
  }

   function formatEcPhoneNumber(value){
    if (!value) {
      return value
    }
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) {
      return phoneNumber
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  function changeEcPhoneNumber(num){
    const formattedPhoneNumber = formatEcPhoneNumber(num)
    setEcPhoneNumber(formattedPhoneNumber)
  }

  function selectProperty(event){

    for(let i=0; i<properties.length; i++){
      if(properties[i].address === event.target.value){
        setLeaseLength(properties[i].leaseLength)
        setMonthlyRent(properties[i].monthlyRent)
        break
      }
    }

    setProperty(event.target.value)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Renter Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property</InputLabel>
                  <Select
                    value={property}
                    style={{borderColor: 'black'}}
                    label="Property Type"
                    onChange={(event) => selectProperty(event)}
                  >
                    {properties.map((property) => {
                      return(
                        <MenuItem key={property.id} value={property.address}>{property.address}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  value={phoneNumber}
                  onChange={(event) => changePhoneNumber(event.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{textAlign: 'center', paddingTop: '10px'}} variant="h6">
                  Emergency Contact
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="ec-name"
                  label="Name"
                  name="ec-name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="ec-phone"
                  label="Phone"
                  name="ec-phone"
                  autoComplete="phone"
                  value={ecPhoneNumber}
                  onChange={(event) => changeEcPhoneNumber(event.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}  
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="ec-relationship"
                  label="Relationship"
                  name="ec-relationship"
                />
              </Grid>
              {errorMessage !== '' && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
             
              {/*<Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: '40px' }}
              disabled={loadingSignUp}
            >
              {loadingSignUp ? (
                <CircularProgress style={{color: 'white'}} size="1.3rem" />
              ) : (
                <Typography>
                  Sign Up 
                </Typography>            
              )}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}