import { DollarOutlined, DashboardOutlined, FilePdfOutlined, MessageOutlined, ToolOutlined, FolderAddOutlined } from '@ant-design/icons';
const menuItems = {
  items: [
      {
          id: 'group-dashboard',
          title: 'Navigation',
          type: 'group',
          children: [
            {
                id: 'billing',
                title: 'Billing',
                type: 'item',
                url: '/billing',
                icon: DollarOutlined,
                breadcrumbs: false
            },
            {
                id: 'documents',
                title: 'Documents',
                type: 'item',
                url: '/documents',
                icon: FilePdfOutlined,
                breadcrumbs: false
            },
            {
                id: 'messages',
                title: 'Messages',
                type: 'item',
                url: '/messages',
                icon: MessageOutlined,
                breadcrumbs: false
            },
          ]
      },
      {
          id: 'maintenance',
          title: 'Maintenance',
          type: 'group',
          children: [
                {
                    id: 'maintenance',
                    title: 'Orders',
                    type: 'item',
                    url: '/maintenance',
                    icon: ToolOutlined,
                    breadcrumbs: true
                },
                {
                    id: 'create-maintenance-order',
                    title: 'Create Order',
                    type: 'item',
                    url: '/create-maintenance-order',
                    icon: FolderAddOutlined,
                    breadcrumbs: true
                },
          ]
      }
  ]
}

export default menuItems